<template>
	<div class="login-page">
		<div class="content">
			<div class="header">
				<div class="logo">
					<img src="../assets/images/home-logo.png" alt="logo" />
				</div>
				<div class="profile" v-if="userinfo" :title="userinfo.userDisplayName">
					<b-dropdown right variant="transparent">
						<template slot="button-content">
							<span>
								<span class="text-muted text-white text-truncate pr-2" style="max-width: 150px;">{{userinfo.userDisplayName}}</span>
								<b-avatar />
							</span>
						</template>
						<b-dropdown-item-button>
							<center>
								<b-avatar size="12em" />
								<div class="py-2 text-muted text-truncate" style="width: 200px;">
									{{userinfo.email}}<br />
									{{userinfo.userDisplayName}}
								</div>
							</center>
						</b-dropdown-item-button>
						<b-dropdown-divider />
						<b-dropdown-item-button class="mt-2">
							<fa-icon icon="cog" />
							<span class="float-right">Settings</span>
						</b-dropdown-item-button>
						<b-dropdown-divider />
						<b-dropdown-item-button @click="logout">
							<fa-icon icon="sign-out-alt" />
							<span class="float-right">Logout</span>
						</b-dropdown-item-button>
					</b-dropdown>
				</div>
			</div>
			<div style="padding-top: 2em">
				<b-btn class="opensidebar" v-b-toggle.mainsidebar></b-btn>
				<b-sidebar ref="mainsidebar" id="mainsidebar" title="Manage Options" bg-variant="dark" text-variant="light" right shadow>
					<div class="px-3 py-2">
						<hr />
						<!--<p>
							more administrative options
						</p>-->
						<section>
							<div class="h6">GAME DEFINITIONS</div>
							<b-list-group flush>
								<b-list-group-item variant="dark" to="/manage/gamedefinitions">My</b-list-group-item>
								<b-list-group-item variant="dark" to="/manage/publishedgamedefinitions">Published</b-list-group-item>
							</b-list-group>
							<div class="h6 pt-4">GAMES</div>
							<b-list-group flush>
								<b-list-group-item variant="dark" to="/manage/games">All</b-list-group-item>
								<b-list-group-item variant="dark" to="/manage/myowned">My Owned</b-list-group-item>
								<b-list-group-item variant="dark" to="/manage/invited">Invited</b-list-group-item>
								<b-list-group-item variant="dark" to="/manage/joined">Joined</b-list-group-item>
							</b-list-group>
							<div class="h6 pt-4">USERS</div>
							<b-list-group flush>
								<b-list-group-item variant="dark" to="/manage/users">Users</b-list-group-item>
							</b-list-group>
						</section>
					</div>
				</b-sidebar>
			</div>
			<router-view></router-view>
		</div>
	</div>
</template>
<script lang="js">
	import { mapState } from 'vuex'

	export default {
		name: 'sidebartemplate',

		computed: {
			...mapState(["userinfo"]),
		},

		methods: {
			async logout() {
				await this.$store.dispatch('logout')
				await this.$router.push('/')
			}
		}
	}
</script>
<style lang="scss">
	.opensidebar {
		position: fixed;
		right: 10px;
		top: 19px;
	}
</style>